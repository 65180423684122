.frequency-container {
  display: flex;
  align-items: flex-start;
}

.dose-form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;

  &__field {
    border-color: #ced4da;
    &--units {
      width: 4rem;
      border-color: #ced4da;
    }
    &__check {
      border-color: #ced4da;
    }
  }

  &__submit {
    display: flex;
    gap: 1rem;
  }
}

.one-time {
  &__input {
    margin-left: 1rem;
  }
}
.cron {
  display: flex;
  flex-direction: column;
}
