.interaction {
  border: solid;
  border-width: 1px;
  border-color: #56cc9d;
  border-radius: 0.4rem;
  background-color: #66867a0b;

  &__medicine {
    font-size: 1.5rem;
    background-color: #66867a1a;
    padding-left: 1rem;
    padding-bottom: 0.25rem;
    text-transform: capitalize;
    border-bottom: 1px solid #0000002d;
  }

  &__match {
    padding-left: 1rem;
  }
}
