@use "../../styles/partials/mixins" as *;

.medications-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;

  @include tablet {
    justify-content: flex-start;
  }
}
