.login-holder {
  display: flex;
  &__name {
    color: white;
    margin-bottom: 0;
    font-size: 1.125rem;
  }
  &__button {
    color: white;
    padding: 0;
    margin-left: 1rem;
  }
}

.settings-button {
  margin-left: 1rem;
}

.drugbug-nav-link {
  margin-right: 1rem;
  &--active {
    font-weight: 700;
    color: white;
    border-bottom: 0.125rem solid white;
  }
}
