.medication-form {
  &__submit {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  }
  &__field {
    border-color: #ced4da;
    &--units {
      width: 6rem;
      border-color: #ced4da;
    }
  }
}

.amount-container {
  display: flex;
}

.doseform-row {
  display: flex;
  justify-content: space-between;
}

.form-check-input {
  border-color: #ced4da;
}

.plus-minus {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__button {
    font-size: 1.5rem;
    margin: 0;
    &--plus {
      color: #49ad85;
    }
    &--minus {
      color: #ff7851;
      font-size: 2rem;
    }
  }
}
