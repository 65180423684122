@use "../../styles/partials/mixins" as *;

.drugbug-footer {
  width: 100%;
  margin-top: 1rem;
  background-color: #66867a;
  min-height: 4rem;
  display: flex;
  align-items: center;

  &__link {
    color: white;
  }

  &__year {
    min-width: fit-content;
    color: white;
    margin: 0 1rem;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
    margin: 1rem 0;
    @include tablet {
      flex-direction: row;
    }
  }
}
